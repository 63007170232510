.HeaderMain {
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0px;
  align-items: center;
  height: 115px;
  width: 100%;
  background-color: #FCC601;
  justify-content: center;
  z-index: 1;
}

.MenuToggleHeaderSideBarOpen {
  display: none;
}

.MenuToggleHeader {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.8);
  border: none;
  display: flex;
  position: fixed;
  top: 20px;
  left: 20px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.MenuToggleHeader:hover {
  background-color: rgba(0,0,0,1);
}

.MenuToggleHeader svg {
  fill: #FCC601;
  width: 26px;
  height: 26px;
}

.SportsFeverHeaderLogo {
  max-height: 150px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.KeepingYouPostedHeaderLogo {
  max-height: 100px;
  max-width: 350px;
  display: none;
  align-items: center;
  justify-content: center;
}

.KeepingYouPostedHeaderLogo .HeaderImage, .SportsFeverHeaderLogo .HeaderImage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.UserInformationDiv {
  position: fixed;
  flex-direction: column;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvatarDiv, .AvatarIcon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: contain;
}

.AvatarDiv:hover {
  cursor: pointer;
}

.ArrowUp {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
}

.UserInformationModalDivSignedIn {
  background-color: ghostwhite;
  border-radius: 8px;
  border: 2px solid black;
  min-height: 100px;
  min-width: 200px;
  position: fixed;
  top: 79px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UserInformationModalDiv {
  background-color: ghostwhite;
  border-radius: 8px;
  border: 2px solid black;
  min-height: 150px;
  min-width: 250px;
  position: fixed;
  top: 79px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginForm {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.LoginButton {
  border-radius: 4px;
  background-color: rgba(0,0,0,0.75);
  box-shadow: 0 2px 10px #888888;
  color: white;
  min-width: 50px;
  min-height: 25px;
  font-family: Arial;
  font-weight: 10;
  font-size: 14px;
}

.LoginButton:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.9);
}

.UserInformationNameDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  background-color: black;
  color: white;
  border-radius: 6px;
  width: 90%;
  padding: 5px;
  margin: 5px auto;
}

.SignOutDiv:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .KeepingYouPostedHeaderLogo {
    display: flex;
  }

  .HeaderMain {
    height: 115px;
  }
}