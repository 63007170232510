.HomeBodyMain {
  background-color: #F5F5F5;
  height: 100%;
  width: 100%;
  margin-top: 115px;
  position: fixed;
}

@media screen and (min-width: 1024px) {
  .HomeBodyMain, .HomeBodyMainSideBarOpen {
    margin-top: 115px;
  }
}