.TextFieldMainDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
}

.TextFieldMainMinifiedDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
}

.TextFieldLabelDiv {
  min-width: 100px;
  margin-bottom: 2px;
}

.TextFieldLabelMinifiedDiv {
  min-width: 75px;
  margin-bottom: 2px;
}

.TextFieldLabel {
  margin: 0px 10px 10px 0px;
  font-family: Arial;
  font-weight: 8;
  font-size: 14px;
}

.TextFieldDiv {
  display: flex;
  flex-direction: column;
}

.TextField {
  min-width: 250px;
  min-height: 35px;
}

.TextFieldLong {
  min-width: 500px;
  min-height: 35px;
}

.TextFieldMinified {
  min-width: 200px;
  min-height: 25px;
}

.TextFieldError {
  color: red;
  font-family: Arial;
  font-weight: 8;
  font-size: 12px;
}

.TextAreaDiv {
  display: flex;
  flex-direction: column;
}

.TextArea {
  min-width: 500px;
  min-height: 350px;
}

.MinifiedTextArea {
  min-width: 500px;
  min-height: 150px;
}