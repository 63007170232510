.LeftSideBarMain {
  display: none;
}

.LeftSideBarMainOpen {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 200px;
  background-color: #000000;
  z-index: 2;
}

.LeftSideBarItems {
  margin-top: 200px;
  width: 90%;
  display: flex;
}

.LeftSideBarItems ul {
  list-style-type: none;
  width: 100%;
  padding: 0px;
}

.LeftSideBarItemDiv {
  min-width: 100%;
  display: flex;
  align-items: center;
  background-color: #FCC601;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.LeftSideBarItemDiv:hover {
  opacity: 0.7;
}

.LeftSideBarItemIcon {
  height: 28px;
  width: 28px;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 20px;
}

.LeftSideBarItemText {
  font-weight: bold;
}

.LeftSideBarItemIcon svg {
  height: 20px;
  width: 20px;
  fill: #FCC601;
}

.MenuToggleHeader {
  display: none;
}

.MenuToggleHeaderSideBarOpen {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #FCC601;
  border: none;
  display: flex;
  position: fixed;
  top: 20px;
  left: 20px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.MenuToggleHeaderSideBarOpen:hover {
  opacity: 0.7;
}

.MenuToggleHeaderSideBarOpen svg {
  fill: #000000;
  width: 26px;
  height: 26px;
}

.LoginDiv {
  position: fixed;
  bottom: 0;
  margin: 20px auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
}

.LoginDivText {
  color: white;
  font-family: Arial;
  font-size: 13px;
  font-weight: 10;
  margin: auto 10px;
}

.LoginDivText:hover {
  cursor: pointer;
}